import React from "react";

import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

import { scherm } from "./common.js";

export { WelkomWinkelkar, Winkelkar };

function WelkomWinkelkar() {
  return (
    <Box>
      <Box>
        <Typography align="center" variant="h6">
          Winkelwagentje
        </Typography>
      </Box>
      <Box>
        <Typography align="center" component={"span"}>
          Laten even je bestelling overlopen. {"\n"}
          Je kan nog aanpassingen doen of terugkeren naar de vorige pagina voor het menu te bekijken.{"\n"}
          Alles in orde? Kies dan bestel en dan zijn we klaar voor de volgende stap!
        </Typography>
      </Box>
    </Box>
  );
}

/*
 * Winkelwagentje
 */
function Winkelkar(props) {
  /* eslint-disable react/jsx-key */

  const shoppingbasket = props.shoppingbasket;
  const nieuwepagina = props.nieuwepagina;
  const menu = props.menu;
  const changeShoppingbasket = props.changeShoppingbasket;

  let totaal = 0;

  return (
    <Container maxWidth="md">
      {React.Children.toArray(
        menu.menu.gerechtgroepen.map((groep) => {
          return (
            <Box>
              <Box pb={8} pt={15}>
                <Typography variant="h6">{groep.naam}</Typography>
              </Box>
              {React.Children.toArray(
                groep.gerechten.map((gerecht) => {
                  if (!shoppingbasket.has(gerecht.naam)) return;
                  const aantal = shoppingbasket.get(gerecht.naam).aantal;
                  const subtotaal = gerecht.prijs * aantal;
                  totaal += subtotaal;

                  return (
                    <Box mb={8}>
                      <Paper>
                        <Grid container spacing={5} alignItems="center">
                          <Grid item xs={2} align="center">
                            <Box>
                              <img src={`fotos/${gerecht.foto}`} width="40" />
                            </Box>
                          </Grid>

                          <Grid item xs={5}>
                            <Typography variant="h7" color="text.secondary">
                              {gerecht.naam}
                            </Typography>
                          </Grid>

                          <Grid item xs={1} align="center">
                            <IconButton
                              color="primary"
                              aria-label="Min"
                              onClick={() => {
                                changeShoppingbasket(gerecht, -1);
                              }}
                            >
                              <RemoveIcon />
                            </IconButton>
                          </Grid>
                          <Grid item xs={1} align="center">
                            <Typography>{aantal}</Typography>
                          </Grid>

                          <Grid item xs={1} align="center">
                            <IconButton
                              color="primary"
                              aria-label="Plus"
                              onClick={() => {
                                changeShoppingbasket(gerecht, 1);
                              }}
                            >
                              <AddIcon />
                            </IconButton>
                          </Grid>

                          <Grid item xs={2} align="center">
                            <Typography>{subtotaal.toLocaleString()}€</Typography>
                          </Grid>
                        </Grid>
                      </Paper>
                    </Box>
                  );
                })
              )}
            </Box>
          );
        })
      )}

      <Box mb={8}>
        <Grid container spacing={5} alignItems="center">
          <Grid item xs={8} align="center">
            <Box></Box>
          </Grid>
          <Grid item xs={2} align="center">
            <Typography variant="h6">Totaal</Typography>
          </Grid>
          <Grid item xs={2} align="center">
            <Typography variant="h6">{totaal.toLocaleString()}€</Typography>
          </Grid>
        </Grid>
      </Box>

      <Box sx={{ display: "flex" }} justifyContent="center" alignItems="center">
        <Button
          style={{ backgroundColor: "#C1D82F", color: "#FFFFFF" }}
          variant="contained"
          onClick={() => {
            nieuwepagina(scherm.BETALING);
          }}
        >
          <Typography variant="h6">Bestel</Typography>
        </Button>
      </Box>
    </Container>
  );
}
