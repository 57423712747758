import React from "react";

import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import GetAppIcon from "@mui/icons-material/GetApp";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

import { useQuery } from "@apollo/client";

import conf from "./conf.json";

import { GET_MENU, scherm } from "./common.js";

export { Menu, Footer, Lader };

/*
 * Terug icon
 */
function TerugIcon(props) {
  const nieuwepagina = props.nieuwepagina;
  const pagina = props.pagina;

  if (
    pagina == scherm.HOMEPAGINA ||
    pagina == scherm.ADMIN ||
    pagina == scherm.ADHOC_BETALING ||
    pagina == scherm.ADHOC_WACHTEN ||
    pagina == scherm.REDIRECT
  )
    return "";

  var terug;
  if (pagina == scherm.WINKELKAR || pagina == scherm.WACHTEN) terug = scherm.HOMEPAGINA;
  else if (pagina == scherm.BETALING) terug = scherm.WINKELKAR;

  return (
    <IconButton
      color="primary"
      aria-label="Terug"
      onClick={() => {
        nieuwepagina(terug);
      }}
    >
      <ArrowBackIcon style={{ color: "#FFFFFF", height: 30 }} />
    </IconButton>
  );
}

/*
 * Shopping basket icon
 */
function ShoppingBasketIcon(props) {
  const nr = props.nr;
  const nieuwepagina = props.nieuwepagina;
  const pagina = props.pagina;

  if (pagina != scherm.HOMEPAGINA) return "";

  if (nr == 0) {
    return <ShoppingCartIcon style={{ color: "#FFFFFF" }} fontSize="large" />;
  }

  return (
    <IconButton
      color="primary"
      aria-label="Winkelwagen"
      onClick={() => {
        nieuwepagina(scherm.WINKELKAR);
      }}
    >
      <Grid container direction="column" justifyContent="center" alignItems="center" spacing={0}>
        <Grid item>
          <ShoppingCartIcon style={{ color: "#FFFFFF" }} fontSize="large" />
        </Grid>
        <Grid item>
          <Box sx={{ height: 10, display: "flex" }} justifyContent="center" alignItems="center">
            <Typography style={{ color: "#FFFFFF", fontSize: 14 }}>{nr}</Typography>
          </Box>
        </Grid>
      </Grid>
    </IconButton>
  );
}

/*
 * Download icon
 */

function DownloadIcon(props) {
  const [open, setOpen] = React.useState(false);
  const token = props.token;

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
    props.download(token, handleClose);
  };

  if (props.pagina != scherm.ADMIN || token == null) return "";

  return (
    <IconButton color="primary" aria-label="Download" onClick={handleOpen}>
      <GetAppIcon style={{ color: "#FFFFFF" }} fontSize="large" />
      <Backdrop open={open}>
        <CircularProgress />
      </Backdrop>
    </IconButton>
  );
}

/*
 * Top menu component
 */
function Menu(props) {
  const pagina = props.pagina;
  const shoppingbasket = props.shoppingbasket;
  const nieuwepagina = props.nieuwepagina;
  const token = props.token;

  let nr = 0;
  shoppingbasket.forEach(function (value /* key */) {
    nr += value.aantal;
  });

  return (
    <Box sx={{ display: "flex", position: "sticky" , top: 0, height: 70, zIndex: 100, background: "white"}}>
      <Grid container spacing={0} alignItems="center">
        <Grid item xs={2}>
          <Box bgcolor="habor.groen" sx={{ height: 70, display: "flex" }} justifyContent="center" alignItems="center">
            <TerugIcon pagina={pagina} nr={nr} nieuwepagina={nieuwepagina} />
          </Box>
        </Grid>
        <Grid item xs>
          <Box bgcolor="habor.groen" sx={{ height: 70 }}></Box>
        </Grid>
        <Grid item>
          <Box sx={{ height: 70 }} align="center" ml={5} mr={5}>
            <img src="logo512.png" height="100%" />
          </Box>
        </Grid>
        <Grid item xs>
          <Box bgcolor="habor.groen" sx={{ height: 70 }}></Box>
        </Grid>
        <Grid item xs={2}>
          <Box bgcolor="habor.groen" sx={{ height: 70, display: "flex" }} justifyContent="center" alignItems="center">
            <ShoppingBasketIcon pagina={pagina} nr={nr} nieuwepagina={nieuwepagina} />
            <DownloadIcon download={props.download} pagina={pagina} token={token} />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

/*
 * Footer met enkele verplichte vermeldingen
 */
function Footer() {
  return (
    <Box pt={5}>
      <Typography variant="body2" color="text.secondary" align="center">
        {"Copyright © "}
        <Link color="inherit" href="https://harmoniebornem.be/">
          Harmonie Bornem
        </Link>{" "}
        {new Date().getFullYear()}
        {". "}
        <Link href="./algemene_voorwaarden.html" color="inherit" target="_blank">
          Algemene voorwaarden.
        </Link>
      </Typography>
    </Box>
  );
}

/*
 * Loader component
 */
function Lader(props) {
  /* eslint-disable no-unused-vars */

  const { loading, error, data } = useQuery(GET_MENU, {
    variables: { key: conf.key },
    onCompleted: (data) => {
      props.handleDB(data);
    },
  });

  if (loading) return null;
  if (error) {
    console.error(error);
    return <p>Error loading menu from database :(</p>;
  }

  return null;
}
