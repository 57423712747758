import React from "react";

import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";

import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { useQuery } from "@apollo/client";

import { GoogleLogin } from "@react-oauth/google";
import jwt_decode from "jwt-decode";

import { GET_OVERZICHT_BESTELLINGEN, GET_OVERZICHT_BESTELLING, GET_OVERZICHT_ADHOC_BETALINGEN } from "./common.js";
import { downloadAdHocBetalingen, downloadBestellingen } from "./download.js";

import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import Button from "@mui/material/Button";

export { Admin };

/*
 * Detail van een bestelling
 
{
  "klant":
      {
          "nr":1,
          "voornaam":"Jan",
          "naam":"Wilmaers",
          "email":"jan.wilmaers@gmail.com"
      }
  ,
  "betaling":
      {
          "nr":1,
          "manier":"bancontact",
          "referentie":"tr_QA9SdVdytT",
          "bedrag":84,
          "status":"paid"
      }
  ,
  "gerechten":[
      {
          "aantal":4,
          "gerechtgroep":"Hoofdgerecht",
          "prijs":21,
          "beschrijving":"De klassieker, onze wereld beroemde paling in ’t groen.",
          "volgorde":1,
          "foto":"palingintgroen.png",
          "name":"Paling in 't groen",
          "lange_beschrijving":"De klassieker, ..."
      }
  ],
  "wanneer":
      {
          "name":"Zondag 3 oktober 2021",
          "start":"11 u",
          "dag":{
              "year":2021,
              "month":10,
              "day":3
          },
          "einde":"13 u",
          "naam":"Zondag 3 oktober 2021"
      }
  ,
  "laatste_wijziging": "...",
  "levering":"afhalen",
  "nr":1,
  "totaal":84,
  "email_ok":true,
  "status":"paid"
}
*/

function detailBestellingHTML(json) {
  const bestelling = JSON.parse(json);

  const wanneer = bestelling.wanneer.naam + ", " + bestelling.wanneer.start + " - " + bestelling.wanneer.einde;

  var html = "";

  html += `
    <h4>Klant</h4>
      <ul>
        <li>Naam: ${bestelling.klant.voornaam} ${bestelling.klant.naam}</li>
        <li>E-mail: ${bestelling.klant.email}</li>
        <li>Nummer: ${bestelling.klant.nr}</li>
      </ul>
    <h4>Status bestelling</h4>
      <ul>
        <li>Nummer: ${bestelling.nr}</li>
        <li>Status: ${bestelling.status}</li>
        <li>Laatste wijziging: ${bestelling.laatste_wijziging}</li>
        <li>E-mail gestuurd: ${bestelling.email_ok}</li>
      </ul>
    <h4>Levering</h4>
      <ul>
        <li>Wanneer: <strong>${wanneer}</strong></li>
        <li>Hoe: ${bestelling.levering}</li>
  `;

  if (bestelling.levering == "bezorging") {
    html += `
        <li>Adres: ${bestelling.straat} ${bestelling.huis}, ${bestelling.postcode} ${bestelling.plaats}</li>
        `;

    if (bestelling.opmerkingen != null && bestelling.opmerkingen !== "") {
      html += `
        <li>Opmerkingen: ${bestelling.opmerkingen}</li>
        `;
    }
  }
  if (bestelling.levering == "afhalen") {
    html += `
        <li>Adres: <strong>zaal Kloosterheide</strong> (Kloosterstraat 89, 2880 Bornem)</li>
        `;
  }

  html += `
    </ul>

    <h4>Betaling</h4>
      <ul>
        <li>Status: ${bestelling.betaling.status}</li>
        <li>Nummer: ${bestelling.betaling.nr}</li>
        <li>Totaal: ${bestelling.betaling.bedrag.toLocaleString()}&euro;</li>
        <li>Manier: ${bestelling.betaling.manier}</li>
        <li>Referentie: ${bestelling.betaling.id}</li>
      </ul>

    <h4>Bestelling</h4>
      <ul>
      `;

  bestelling.gerechten.forEach((gerecht) => {
    html += `
      <li>${gerecht.aantal} x ${gerecht.name}</li>
  `;
  });

  html += `
    </ul>
  `;

  return html;
}

function DetailBestelling(props) {
  const { loading, error, data } = useQuery(GET_OVERZICHT_BESTELLING, {
    variables: { token: props.token, bestellingNr: props.bestellingNr },
  });

  if (props.bestellingNr == 0) return null;

  if (loading) return <CircularProgress />;
  if (error) return `Fout bij laden detail bestelling! ${error.message}`;

  if (data == null) return null;

  return (
    <Box>
      {" "}
      <div dangerouslySetInnerHTML={{ __html: detailBestellingHTML(data.overzichtBestellingJSON) }} />
    </Box>
  );
}

/*
 * Overzicht van alle bestellingen
 *
 * JSON:
 * [ {
 *   "betaling_manier": "bancontact",
 *   "nr": 1,
 *   "totaal": 65.5,
 *   "voornaam": "Jan",
 *   "dag": "Zondag 3 oktober 2021",
 *   "betaling_status": "paid",
 *   "levering": "bezorging",
 *   "naam": "Wilmaers",
 *   "email": "jan.wilmaers@gmail.com",
 *   "status": "paid"
 * }, ... ]
 */

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function OverzichtBestellingen(props) {
  const { loading, error, data } = useQuery(GET_OVERZICHT_BESTELLINGEN, {
    variables: { token: props.token },
    pollInterval: 3000,
  });

  const [open, setOpen] = React.useState(0);

  const handleClickOpen = (nr) => {
    setOpen(nr);
  };

  const handleClose = () => {
    setOpen(0);
  };

  if (loading) return <CircularProgress />;
  if (error) return `Fout bij laden overzicht bestellingen! ${error.message}`;

  if (data == null) return null;
  const json = JSON.parse(data.overzichtAlleBestellingenJSON);
  if (json == null) return null;

  const columns = [
    { field: "nr", headerName: "Nummer", flex: 1, type: "number", headerAlign: "center", align: "center" },
    { field: "voornaam", headerName: "Voornaam", flex: 1 },
    { field: "naam", headerName: "Naam", flex: 1 },
    { field: "email", headerName: "E-mail", flex: 1, hide: true },
    {
      field: "totaal",
      headerName: "Totaal",
      flex: 1,
      headerAlign: "center",
      align: "center",
      valueFormatter: (params) => {
        return `${params.value.toLocaleString()}€`;
      },
    },
    {
      field: "dag",
      headerName: "Dag",
      flex: 1,
      headerAlign: "center",
      align: "center",

      valueFormatter: (params) => {
        return params.value.split(" ")[0];
      },
    },
    { field: "levering", headerName: "Levering", flex: 1, headerAlign: "center", align: "center" },
    { field: "status", headerName: "Status bestelling", flex: 1, headerAlign: "center", align: "center" },
    { field: "betaling_status", headerName: "Status betaling", flex: 1, headerAlign: "center", align: "center" },
    { field: "betaling_manier", headerName: "Manier van betalen", flex: 1, headerAlign: "center", align: "center" },
  ];

  const rows = [];
  json.forEach((bestelling) => {
    rows.push({
      id: bestelling.nr,
      ...bestelling,
    });
  });

  return (
    <Box sx={{ height: "90vh", width: "100%" }}>
      <DataGrid
        rows={rows}
        columns={columns}
        components={{
          Toolbar: GridToolbar,
        }}
        onRowClick={(params /* event */) => {
          handleClickOpen(params.id);
        }}
      />

      <Dialog fullScreen open={open != 0} onClose={handleClose} TransitionComponent={Transition}>
        <AppBar position="relative">
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
            <Typography variant="h6">Bestelling {open}</Typography>
          </Toolbar>
        </AppBar>
        <Box>
          <DetailBestelling token={props.token} bestellingNr={open} />
        </Box>
      </Dialog>
    </Box>
  );
}

/*
 * Overzicht van alle ad-hoc betalingen (QR code)
 *
 * JSON:
 * [ {
 * "nr": 1.0,
 * "qrCode": "",
 * "rekening": "",
 * "aangemaakt": "11/07/2022 15:15:01",
 * "mededeling": "Eetfestijn 2022",
 * "medewerker": "Jan Wilmaers",
 * "fout": "",
 * "id": "tr_WMK9f2Drac",
 * "referentie": "",
 * "naam": "",
 * "bedrag": 123.0,
 * "status": "open"
 * }, ... ]
 */

function OverzichtAdHocBetalingen(props) {
  const { loading, error, data } = useQuery(GET_OVERZICHT_ADHOC_BETALINGEN, {
    variables: { token: props.token },
    pollInterval: 3000,
  });

  if (loading) return <CircularProgress />;
  if (error) return `Fout bij laden overzicht ad-hoc betalingen! ${error.message}`;

  if (!data) return null;
  const json = JSON.parse(data.overzichtAdHocBetalingenJSON);
  if (json == null) return null;

  const columns = [
    { field: "nr", headerName: "Nummer", flex: 1, type: "number", headerAlign: "center", align: "center" },
    {
      field: "bedrag",
      headerName: "Bedrag",
      flex: 1,
      headerAlign: "center",
      align: "center",
      valueFormatter: (params) => {
        return `${params.value.toLocaleString()}€`;
      },
    },
    { field: "status", headerName: "Status", flex: 1, headerAlign: "center", align: "center" },
    { field: "aangemaakt", headerName: "Aangemaakt", flex: 1, headerAlign: "center", align: "center" },
    { field: "mededeling", headerName: "Mededeling", flex: 1, headerAlign: "center", align: "center" },
    { field: "referentie", headerName: "Referentie", flex: 1, headerAlign: "center", align: "center" },
    { field: "medewerker", headerName: "Medewerker", flex: 1, headerAlign: "center", align: "center" },
    { field: "qrCode", headerName: "QR Code", flex: 1, headerAlign: "center", align: "center" },
    { field: "fout", headerName: "Fout", flex: 1, headerAlign: "center", align: "center" },
  ];

  const rows = [];
  json.forEach((bestelling) => {
    rows.push({
      id: bestelling.nr,
      ...bestelling,
    });
  });

  return (
    <Box sx={{ height: "90vh", width: "100%" }}>
      <DataGrid
        rows={rows}
        columns={columns}
        components={{
          Toolbar: GridToolbar,
        }}
      />
    </Box>
  );
}

/*
 * Admin pagina
 */
class Admin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      naam: null,
      overzichtBestellingen: false,
      overzichtAdHocBetalingen: false,
    };
    this.token = props.token;
    this.setToken = props.setToken;
    this.setDownload = props.setDownload;
  }

  render() {
    return (
      <Box>
        {this.token == null ? (
          <Box>
            <Box m={20}>
              <Typography align="center" variant="h6">
                Log in met uw Harmonie Bornem account.
              </Typography>
            </Box>
            <Box m={20} display="flex" justifyContent="center" alignItems="center">
              <GoogleLogin
                onSuccess={(response) => {
                  const decoded = jwt_decode(response.credential);
                  const name = decoded.name;
                  console.log("Login OK: " + name);
                  this.setToken(response.credential);
                  this.token = response.credential;
                  this.setState({
                    naam: name,
                  });
                }}
                onFailure={(response) => {
                  console.log("Fout bij inloggen: " + JSON.stringify(response));
                  this.setToken(null);
                }}
                hosted_domain="harmoniebornem.be"
              />
            </Box>
          </Box>
        ) : (
          <Box>
            {!this.state.overzichtBestellingen && !this.state.overzichtAdHocBetalingen ? (
              <Box>
                <Box sx={{ display: "flex", m: 20 }} justifyContent="center" alignItems="center">
                  <Button
                    sx={{ width: "50%" }}
                    style={{ backgroundColor: "#C1D82F", color: "#FFFFFF" }}
                    variant="contained"
                    onClick={() => {
                      this.setState({
                        overzichtBestellingen: true,
                      });
                      this.setDownload(downloadBestellingen);
                    }}
                  >
                    <Typography variant="h6">Overzicht online bestellingen</Typography>
                  </Button>
                </Box>

                <Box>
                  <Box sx={{ display: "flex", m: 20 }} justifyContent="center" alignItems="center">
                    <Button
                      sx={{ width: "50%" }}
                      style={{ backgroundColor: "#C1D82F", color: "#FFFFFF" }}
                      variant="contained"
                      onClick={() => {
                        this.setState({
                          overzichtAdHocBetalingen: true,
                        });
                        this.setDownload(downloadAdHocBetalingen);
                      }}
                    >
                      <Typography variant="h6">Overzicht betalingen met QR code</Typography>
                    </Button>
                  </Box>
                </Box>
              </Box>
            ) : null}
            {this.state.overzichtBestellingen ? <OverzichtBestellingen token={this.token} /> : null}
            {this.state.overzichtAdHocBetalingen ? <OverzichtAdHocBetalingen token={this.token} /> : null}
          </Box>
        )}
      </Box>
    );
  }
}
