import React from "react";

import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";

import { scherm, UPDATE_BETALING_STATUS } from "./common.js";
import { useMutation } from "@apollo/client";

import conf from "./conf.json";

export { Bedankt };

/*
 * Bedankt pagina
 */
function Bedankt(props) {
  /* eslint-disable no-unused-vars */

  const nieuwepagina = props.nieuwepagina;
  const setRedirect = props.setRedirect;
  const setLopendeBestelling = props.setLopendeBestelling;
  const lopendeBestelling = props.lopendeBestelling;

  const [updateBetalingStatus, { data, loading, error }] = useMutation(UPDATE_BETALING_STATUS, {
    variables: { bestellingNr: parseInt(lopendeBestelling) },
  });

  if (loading) return <CircularProgress />;
  if (error) return `Interne fout! ${error.message}`;

  return (
    <Box>
      {conf.gebruik_mollie ? (
        <Box>
          <Typography variant="h6">Hartelijk dank voor uw bestelling!</Typography>
          <Typography>Nummer bestelling: {lopendeBestelling}</Typography>
          <Typography>U onvangt een e-mail met een bevestiging van zodra wij uw betaling hebben verwerkt.</Typography>
        </Box>
      ) : (
        <Box>
          <Typography variant="h6">Hartelijk dank voor uw bestelling!</Typography>
          <Typography>Nummer bestelling: {lopendeBestelling}</Typography>
          <Typography>U onvangt zo dadelijk een e-mail met de betalingsinstructies.</Typography>
        </Box>
      )}
      <Box m={10} sx={{ display: "flex" }} justifyContent="center" alignItems="center">
        <Button
          style={{ backgroundColor: "#C1D82F", color: "#FFFFFF" }}
          variant="contained"
          onClick={() => {
            if (conf.gebruik_mollie) updateBetalingStatus();
            localStorage.removeItem("shoppingbasket");
            const { protocol, host, pathname } = window.location;
            const fullUrlWithoutParams = `${protocol}//${host}${pathname}`;
            setRedirect(fullUrlWithoutParams);
            setLopendeBestelling(0);
            nieuwepagina(scherm.REDIRECT);
          }}
        >
          <Typography variant="h6">OK</Typography>
        </Button>
      </Box>
    </Box>
  );
}
