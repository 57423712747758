import React, { useEffect } from "react";

import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

{/*}
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
*/}

import { useMutation } from "@apollo/client";

import conf from "./conf.json";

import { scherm, CREATE_BESTELLING } from "./common.js";

export { Betaling };

/*
 * Betaal pagina
 */
class Betaling extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      voornaam: "",
      naam: "",
      email: "",
      startbetaling: false,
      levering: "bezorging",
      straat: "",
      huis: "",
      postcode: "",
      plaats: "",
      opmerkingen: "",
      dag: "",
    };
    this.shoppingbasket = props.shoppingbasket;
    this.nieuwepagina = props.nieuwepagina;
    this.setRedirect = props.setRedirect;
    this.setLopendeBestelling = props.setLopendeBestelling;
    this.menu = props.menu;

    var totaal = 0;
    this.shoppingbasket.forEach(function (value /* key */) {
      totaal += value.aantal * value.gerecht.prijs;
    });
    this.totaal = totaal;

    this.handleInputChange = this.handleInputChange.bind(this);
    this.setStartBetaling = this.setStartBetaling.bind(this);
  }
  // Knop aan wanneer alle verplichte velden ingevuld zijn
  isEnabled() {
    const minIngevuld =
      this.state.voornaam !== "" &&
      this.state.naam !== "" &&
      this.state.email !== "" &&
      this.state.dag !== "" &&
      this.state.levering
        ? true
        : false;

    if (minIngevuld && this.state.levering === "bezorging")
      return this.state.straat !== "" &&
        this.state.huis !== "" &&
        this.state.postcode !== "" &&
        this.state.plaats !== ""
        ? true
        : false;

    return minIngevuld;
  }

  handleInputChange(event) {
    const target = event.target;

    if (target.name == "email") target.value = target.value.toLowerCase();

    this.setState({
      [target.name]: target.value,
    });
  }

  setStartBetaling(startbetaling, url = null) {
    this.setState({ startbetaling: startbetaling });

    if (!startbetaling && !conf.gebruik_mollie) {
      this.nieuwepagina(scherm.BEDANKT);
    }

    if (url) {
      this.setRedirect(url);
      this.nieuwepagina(scherm.REDIRECT);
    }
  }

  render() {
    return (
      <Box>
        <Box>
          <Typography align="center" variant="h5">{`Afrekenen: totaal ${this.totaal.toLocaleString()}€`}</Typography>
        </Box>

        <Box pt={10} m={10} sx={{ display: "flex" }}>
          <Box width={1}>
            <Typography variant="h6" align="left">
              Wie ben je?
            </Typography>
            <Grid container spacing={5}>
              <Grid item xs={4} align="left">
                <TextField
                  required
                  name="voornaam"
                  label="Voornaam"
                  value={this.state.voornaam}
                  onChange={this.handleInputChange}
                />
              </Grid>

              <Grid item xs={8} align="left">
                <TextField
                  fullWidth
                  required
                  name="naam"
                  label="Naam"
                  value={this.state.naam}
                  onChange={this.handleInputChange}
                />
              </Grid>

              <Grid item xs={12} align="left">
                <TextField
                  required
                  fullWidth
                  name="email"
                  label="E-mail"
                  value={this.state.email}
                  onChange={this.handleInputChange}
                />
              </Grid>
            </Grid>
          </Box>
        </Box>

        <Box pt={10} m={10} sx={{ display: "flex" }}>
          <Box width={1}>
            <Typography variant="h6" align="left">
              Voor wanneer is je bestelling?
            </Typography>

            <Box>
              <FormControl fullWidth>
                <InputLabel required id="dag">
                  Wanneer
                </InputLabel>
                <Select
                  labelId="dag"
                  id="dag"
                  name="dag"
                  value={this.state.dag}
                  label="Dag"
                  required
                  onChange={this.handleInputChange}
                >
                  {this.menu.menu.dagen.map((dag) => (
                    <MenuItem value={dag} key={dag}>
                      {dag}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Box>
        </Box>

{/*
        <Box pt={10} m={10} sx={{ display: "flex" }}>
          <Box width={1}>
            <Typography variant="h6" align="left">
              Hoe wil je je bestelling ontvangen?
            </Typography>

            <Box align="left">
              <FormControl>
                <RadioGroup
                  aria-label="levering"
                  name="levering"
                  value={this.state.levering}
                  required
                  onChange={this.handleInputChange}
                >
                  <FormControlLabel value="afhalen" control={<Radio />} label="Afhalen zaal Kloosterheide" />
                  <FormControlLabel
                    value="bezorging"
                    control={<Radio />}
                    label="Bezorging (Bornem, Puurs-Sint-Amands, Temse)"
                  />
                </RadioGroup>
              </FormControl>
            </Box>
          </Box>
        </Box>
*/}
        <Box pt={10} m={10} sx={{ display: "flex" }}>
          {this.state.levering == "bezorging" ? (
            <Box width={1}>
              <Typography variant="h6" align="left">
                Waar wil je dat we leveren?
              </Typography>
              <Grid container spacing={5}>
                <Grid item xs={8} align="left">
                  <TextField
                    fullWidth
                    required
                    name="straat"
                    label="Straat"
                    value={this.state.straat}
                    onChange={this.handleInputChange}
                  />
                </Grid>

                <Grid item xs={4} align="left">
                  <TextField
                    fullWidth
                    required
                    name="huis"
                    label="Huisnummer"
                    value={this.state.huis}
                    onChange={this.handleInputChange}
                  />
                </Grid>

                <Grid item xs={4} align="left">
                  <TextField
                    required
                    fullWidth
                    name="postcode"
                    label="Postcode"
                    value={this.state.postcode}
                    onChange={this.handleInputChange}
                  />
                </Grid>

                <Grid item xs={8} align="left">
                  <TextField
                    required
                    fullWidth
                    name="plaats"
                    label="Gemeente"
                    value={this.state.plaats}
                    onChange={this.handleInputChange}
                  />
                </Grid>

                <Grid item xs={12} align="left">
                  <TextField
                    fullWidth
                    name="opmerkingen"
                    label="Bijkomende opmerkingen"
                    value={this.state.opmerkingen}
                    onChange={this.handleInputChange}
                  />
                </Grid>
              </Grid>
            </Box>
          ) : null}
        </Box>

        <Box sx={{ display: "flex" }} justifyContent="center" alignItems="center">
          <Button
            disabled={!this.isEnabled()}
            style={{ backgroundColor: "#C1D82F", color: "#FFFFFF" }}
            variant="contained"
            onClick={() => {
              this.setState({ startbetaling: true });
            }}
          >
            <Typography variant="h6">Betaal</Typography>
          </Button>
        </Box>

        <Box m={10} sx={{ display: "flex" }} justifyContent="center" alignItems="center">
          {this.state.startbetaling ? (
            <StuurBetaalgegevens
              startbetaling={this.state.startbetaling}
              voornaam={this.state.voornaam}
              naam={this.state.naam}
              email={this.state.email}
              levering={this.state.levering}
              straat={this.state.straat}
              huis={this.state.huis}
              postcode={this.state.postcode}
              plaats={this.state.plaats}
              opmerkingen={this.state.opmerkingen}
              dag={this.state.dag}
              shoppingbasket={this.shoppingbasket}
              totaal={this.totaal}
              setStartBetaling={this.setStartBetaling}
              setRedirect={this.setRedirect}
              setLopendeBestelling={this.setLopendeBestelling}
            />
          ) : null}
        </Box>
      </Box>
    );
  }
}

/*
 * Stuur order en betaalgegevens naar server
 */
function StuurBetaalgegevens(props) {
  const [createBestelling, { data, loading, error }] = useMutation(CREATE_BESTELLING);

  useEffect(() => {
    var gerechten = [];
    props.shoppingbasket.forEach(function (value /* key */) {
      for (let i = 1; i <= value.aantal; i++) gerechten.push(value.gerecht.naam);
    });

    createBestelling({
      variables: {
        voornaam: props.voornaam,
        naam: props.naam,
        email: props.email,
        gerechten: gerechten,
        totaal: props.totaal,
        key: conf.key,
        levering: props.levering,
        straat: props.straat,
        huis: props.huis,
        postcode: props.postcode,
        plaats: props.plaats,
        opmerkingen: props.opmerkingen,
        dag: props.dag,
      },
    });
  }, [props.startbetaling]);

  if (loading) return <CircularProgress />;
  if (error) return `Fout bij betaling! ${error.message}`;

  if (data && data.createBestelling && data.createBestelling.bestellingNr) {
    props.setStartBetaling(false, data.createBestelling.betaalUrl);
    props.setLopendeBestelling(data.createBestelling.bestellingNr);
  }

  return "Doorverwijzing naar betaalpagina ...";
}
