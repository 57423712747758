import conf from "./conf.json";
import {
  GET_MENU,
  GET_OVERZICHT_BESTELLINGEN,
  GET_OVERZICHT_BESTELLING,
  GET_OVERZICHT_ADHOC_BETALINGEN,
  apolloClient,
} from "./common.js";

export { downloadBestellingen, downloadAdHocBetalingen };

async function downloadBestellingen(token, done) {
  /* eslint-disable no-unused-vars, no-redeclare */

  // Lijst met gerechten
  var { loading, error, data } = await apolloClient.query({
    query: GET_MENU,
    variables: { key: conf.key },
  });

  const gerechten = [];
  data.menu.gerechtgroepen.forEach((groep) => {
    groep.gerechten.forEach((gerecht) => {
      gerechten.push(gerecht.naam);
    });
  });

  // Kolommen
  const csv = [];
  const row = [
    "bestelling_nr",
    "voornaam",
    "naam",
    "email",
    "totaal",
    "bestelling_status",
    "email_ok",
    "levering",
    "dag",
    "straat",
    "huis",
    "postcode",
    "plaats",
    "opmerkingen",
    "betaling_nr",
    "betaling_manier",
    "betaling_status",
    "betaling_referentie",
    "laatste_wijziging",
    ...gerechten,
  ];
  csv.push(row.join(";"));

  // Lijst met bestellingen
  var { loading, error, data } = await apolloClient.query({
    query: GET_OVERZICHT_BESTELLINGEN,
    variables: { token: token },
  });

  const json = JSON.parse(data.overzichtAlleBestellingenJSON);

  const bestellingen = [];
  json.forEach((bestelling) => {
    bestellingen.push(bestelling["nr"]);
  });

  // Loop over bestellingen
  for (let nr of bestellingen) {
    const { loading, error, data } = await apolloClient.query({
      query: GET_OVERZICHT_BESTELLING,
      variables: { token: token, bestellingNr: nr },
    });
    const bestelling = JSON.parse(data.overzichtBestellingJSON);

    const row = [
      bestelling.nr, // "bestelling_nr",
      bestelling.klant.voornaam, // "voornaam",
      bestelling.klant.naam, // "naam",
      bestelling.klant.email, // "email",
      bestelling.totaal.toLocaleString(), // "totaal",
      bestelling.status, // "bestelling_status",
      bestelling.email_ok, // "email_ok",
      bestelling.levering, // "levering",
      bestelling.wanneer.naam, // "dag",
      bestelling.straat, // "straat",
      bestelling.huis, // "huis",
      bestelling.postcode, // "postcode",
      bestelling.plaats, // "plaats",
      bestelling.opmerkingen, // "opmerkingen",
      bestelling.betaling.nr, // "betaling_nr",
      bestelling.betaling.manier, // "betaling_manier",
      bestelling.betaling.status, // "betaling_status",
      bestelling.betaling.referentie, // "betaling_referentie",
      bestelling.laatste_wijziging, // "laatste_wijziging",
      // ...gerechten,
    ];

    for (let i of gerechten) {
      let aantal = 0;
      for (let j of bestelling.gerechten) {
        if (j.naam == i) {
          aantal = j.aantal;
          break;
        }
      }
      row.push(aantal);
    }
    csv.push(row.join(";"));
  }

  // Download file
  const element = document.createElement("a");
  const file = new Blob([csv.join("\n")], { type: "text/plain" });
  element.href = URL.createObjectURL(file);
  element.download = "bestellingen.csv";
  document.body.appendChild(element);
  element.click();
  done();
}

async function downloadAdHocBetalingen(token, done) {
  /* eslint-disable no-unused-vars, no-redeclare */

  const csv = [];
  const row = ["nr", "bedrag", "status", "aangemaakt", "mededeling", "referentie", "medewerker", "qrCode", "fout"];
  csv.push(row.join(";"));

  var { loading, error, data } = await apolloClient.query({
    query: GET_OVERZICHT_ADHOC_BETALINGEN,
    variables: { token: token },
  });

  const json = JSON.parse(data.overzichtAdHocBetalingenJSON);

  json.forEach((betaling) => {
    const row = [
      betaling.nr,
      betaling.bedrag.toLocaleString(),
      betaling.status,
      betaling.aangemaakt,
      betaling.mededeling,
      betaling.referentie,
      betaling.medewerker,
      betaling.qrCode,
      betaling.fout,
    ];
    csv.push(row.join(";"));
  });

  const element = document.createElement("a");
  const file = new Blob([csv.join("\n")], { type: "text/plain" });
  element.href = URL.createObjectURL(file);
  element.download = "betalingen.csv";
  document.body.appendChild(element);
  element.click();
  done();
}
