import React from "react";

import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

import CircularProgress from "@mui/material/CircularProgress";

import { useQuery } from "@apollo/client";

import { GET_BETALING_STATUS, scherm } from "./common.js";

export { StatusBetaling };

/*
 * Wacht scherm tot betaling van Mollie is ontvangen (pollen van status in database)
 */
function StatusBetaling(props) {
  const params = new URLSearchParams(document.location.search.substring(1));
  const nr = parseInt(params.get("bestelling"), 10);
  const { loading, error, data } = useQuery(GET_BETALING_STATUS, {
    variables: { bestellingNr: nr },
    pollInterval: 250,
    onCompleted: (/* data */) => {
      if (status.betalingStatus == "paid" || status.betalingStatus == "open") {
        props.setKlant(status.klantNr);
        props.nieuwepagina(scherm.BEDANKT);
      } else if (status.betalingStatus) {
        props.setLopendeBestelling(0);
        props.nieuwepagina(scherm.HOMEPAGINA);
      }
    },
  });

  if (nr == null) return null;

  if (nr != props.lopendeBestelling) {
    console.log(`Verschillende bestelling nummer, verwachte ${props.lopendeBestelling}, ontving ${nr}`);
    props.setLopendeBestelling(0);
    props.nieuwepagina(scherm.HOMEPAGINA);
    return null;
  }

  if (loading) return <p>Loading...</p>;
  if (error) {
    console.error(error);
    return <p>Error :(</p>;
  }

  const status = data.betalingStatus;
  if (status == null) return null;

  return (
    <Box align="center">
      <Box p={15}>
        <CircularProgress />
      </Box>
      <Box>
        <Typography>bestellingNr: {status.bestellingNr}</Typography>
        <Typography>betalingNr: {status.betalingNr}</Typography>
        <Typography>klantNr: {status.klantNr}</Typography>
      </Box>
    </Box>
  );
}
