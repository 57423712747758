import React from "react";

import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import CircularProgress from "@mui/material/CircularProgress";
import Hidden from "@mui/material/Hidden";

export { Welkom, Menukaart };

/*
 * Welkom tekstje
 * TO DO: maak dynamisch met datums
 */
function Welkom(props) {
  if (props.menu == null) return null;

  return (
    <Box>
      <Box>
        <Typography align="center" variant="h4">{props.menu.menu.titel}</Typography>
      </Box>
      <Box>
        <Typography align="center" style={{ whiteSpace: "pre-line" }}>{props.menu.menu.begroeting}</Typography>
      </Box>
    </Box>
  );
}

/*
 * Menukaart
 */
function Menukaart(props) {
  const menu = props.menu;
  const changeShoppingbasket = props.changeShoppingbasket;

  if (menu == null) return <CircularProgress />;

  return (
    <Container maxWidth="lg">
      {menu.menu.gerechtgroepen.map((groep) => {
        return (
          <Box key={groep.naam}>
            <Box pb={8} pt={15}>
              <Typography align="center" variant="h5">{groep.naam}</Typography>
            </Box>
            {groep.gerechten.map((gerecht) => {
              return (
                <Box mb={8} key={gerecht.naam}>
                  <Paper>
                    <Grid container spacing={5} alignItems="center">
                      <Grid item xs={2} align="center">
                        <Box p={5}>
                          <img src={`fotos/${gerecht.foto}`} width="100%" />
                        </Box>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant="h6" color="text.secondary">
                          {gerecht.naam}
                        </Typography>
                        <Hidden only={["md", "lg", "xl"]}>
                          <Typography>{gerecht.beschrijving}</Typography>
                        </Hidden>
                        <Hidden only={["xs", "sm"]}>
                          <Typography>{gerecht.lange_beschrijving}</Typography>
                        </Hidden>
                      </Grid>
                      <Grid item xs={2} align="center">
                        <Typography>{gerecht.prijs.toLocaleString()}€</Typography>
                      </Grid>
                      <Grid item xs={2} align="center">
                        <IconButton
                          color="primary"
                          aria-label="Voeg toe"
                          onClick={() => {
                            changeShoppingbasket(gerecht, 1);
                          }}
                        >
                          <AddShoppingCartIcon />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </Paper>
                </Box>
              );
            })}
          </Box>
        );
      })}
    </Container>
  );
}
