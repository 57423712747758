import React from "react";
import ReactDOM from "react-dom";

import Container from "@mui/material/Container";

import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";

import { ApolloProvider } from "@apollo/client";

import { GoogleOAuthProvider } from "@react-oauth/google";

import { theme, scherm, apolloClient } from "./common.js";
import { Menu, Footer, Lader } from "./menu.js";
import { Welkom, Menukaart } from "./homepagina.js";
import { WelkomWinkelkar, Winkelkar } from "./winkelkar.js";
import { Betaling } from "./betaling.js";
import { StatusBetaling } from "./wachten.js";
import { Bedankt } from "./bedankt.js";
import { Admin } from "./admin.js";
import { AdHocBetaling } from "./adhoc_betaling.js";
import { AdHocStatusBetaling } from "./adhoc_wachten.js";

/*
 * Top level app React component
 *
 * State variables:
 * - menu: volledige structuur van menu (inclusief prijzen), zoals geladen van database
 * - pagina: de huidige (of nieuwe) pagina
 * - shoppingbasket: map met gerechten
 * - redirect: url voor de betaalsite (mollie), wordt gezet na doorsturen betaalgegevens
 * - lopendeBestelling: nr van de lopende bestelling (gestuurd naar database maar nog niet betaald)
 * - klant: nr van de klant als een bestelling volledig afgerond is
 * - token: JWT token indien ingelogd (admin functie)
 *
 * State callbacks:
 * - handlDB: laat menu
 * - changeShoppingbasket
 * - nieuwepagina
 * - setRedirect
 * - setLopendeBestelling
 * - setKlant
 * - setToken
 */
class App extends React.Component {
  constructor(props) {
    super(props);
    this.handleDB = this.handleDB.bind(this);
    this.changeShoppingbasket = this.changeShoppingbasket.bind(this);
    this.nieuwepagina = this.nieuwepagina.bind(this);
    this.setRedirect = this.setRedirect.bind(this);
    this.setLopendeBestelling = this.setLopendeBestelling.bind(this);
    this.setKlant = this.setKlant.bind(this);
    this.setToken = this.setToken.bind(this);
    this.setDownload = this.setDownload.bind(this);
    const _lopendeBestelling = localStorage.getItem("lopendeBestelling");
    const _klant = localStorage.getItem("klant");
    const _shoppingbasket = localStorage.getItem("shoppingbasket");
    const _token = localStorage.getItem("token");
    this.state = {
      menu: null,
      pagina: scherm.HOMEPAGINA,
      shoppingbasket: _shoppingbasket ? new Map(JSON.parse(_shoppingbasket)) : new Map(),
      redirect: null,
      lopendeBestelling: _lopendeBestelling ? _lopendeBestelling : 0,
      klant: _klant ? _klant : 0,
      token: _token ? _token : null,
      download: null,
    };

    // Redirect na betaling bij Mollie, heeft URL parameter met bestelling of ad-hoc betaling nummer
    const params = new URLSearchParams(document.location.search.substring(1));
    if (params.has("bestelling")) this.state.pagina = scherm.WACHTEN;
    if (params.has("betaling")) this.state.pagina = scherm.ADHOC_WACHTEN;

    // /admin or pay?
    const path = window.location.pathname;
    const parts = path.split("/");
    const lastSegment = parts.pop() || parts.pop(); // handle potential trailing slash
    if (lastSegment == "admin") this.state.pagina = scherm.ADMIN;
    if (lastSegment == "pay") this.state.pagina = scherm.ADHOC_BETALING;
  }

  nieuwepagina(pagina) {
    this.setState({
      pagina: pagina,
    });
  }

  handleDB(data) {
    this.setState({ menu: data });
  }

  setRedirect(url) {
    this.setState({ redirect: url });
  }

  setLopendeBestelling(bestelling) {
    this.setState({ lopendeBestelling: bestelling });
    localStorage.setItem("lopendeBestelling", bestelling);
  }

  setKlant(klant) {
    this.setState({ klant: klant });
    localStorage.setItem("klant", klant);
  }

  setToken(token) {
    this.setState({ token: token });
    localStorage.setItem("token", token);
  }

  setDownload(download) {
    this.setState({ download: download });
  }

  changeShoppingbasket(gerecht, stap) {
    const shoppingbasket = new Map(this.state.shoppingbasket);

    if (!shoppingbasket.has(gerecht.naam)) {
      shoppingbasket.set(gerecht.naam, {
        aantal: 0,
        gerecht: gerecht,
      });
    }

    const item = shoppingbasket.get(gerecht.naam);
    const nieuwaantal = item.aantal + stap;

    if (nieuwaantal == 0) {
      shoppingbasket.delete(gerecht.naam);
    } else {
      shoppingbasket.set(gerecht.naam, {
        aantal: nieuwaantal,
        gerecht: item.gerecht,
      });
    }
    this.setState({ shoppingbasket: shoppingbasket });
    localStorage.setItem("shoppingbasket", JSON.stringify([...shoppingbasket]));
  }

  render() {
    const paginas = new Map();
    paginas.set(
      scherm.HOMEPAGINA,
      <Container>
        <Welkom menu={this.state.menu} />
        <Menukaart
          menu={this.state.menu}
          changeShoppingbasket={this.changeShoppingbasket}
          nieuwepagina={this.nieuwepagina}
        />
      </Container>
    );
    paginas.set(
      scherm.WINKELKAR,
      <Container>
        <WelkomWinkelkar />
        <Winkelkar
          menu={this.state.menu}
          shoppingbasket={this.state.shoppingbasket}
          nieuwepagina={this.nieuwepagina}
          changeShoppingbasket={this.changeShoppingbasket}
        />
      </Container>
    );
    paginas.set(
      scherm.BETALING,
      <Container>
        <Betaling
          menu={this.state.menu}
          shoppingbasket={this.state.shoppingbasket}
          nieuwepagina={this.nieuwepagina}
          setRedirect={this.setRedirect}
          setLopendeBestelling={this.setLopendeBestelling}
        />
      </Container>
    );
    paginas.set(
      scherm.WACHTEN,
      <Container>
        <StatusBetaling
          nieuwepagina={this.nieuwepagina}
          lopendeBestelling={this.state.lopendeBestelling}
          setLopendeBestelling={this.setLopendeBestelling}
          setKlant={this.setKlant}
        />
      </Container>
    );
    paginas.set(
      scherm.BEDANKT,
      <Container>
        <Bedankt
          lopendeBestelling={this.state.lopendeBestelling}
          setLopendeBestelling={this.setLopendeBestelling}
          nieuwepagina={this.nieuwepagina}
          setRedirect={this.setRedirect}
        />
      </Container>
    );
    paginas.set(
      scherm.ADMIN,
      <Container>
        <Admin setDownload={this.setDownload} token={this.state.token} setToken={this.setToken} />
      </Container>
    );
    paginas.set(
      scherm.ADHOC_BETALING,
      <Container>
        <AdHocBetaling
          nieuwepagina={this.nieuwepagina}
          setRedirect={this.setRedirect}
          token={this.state.token}
          setToken={this.setToken}
        />
      </Container>
    );
    paginas.set(
      scherm.ADHOC_WACHTEN,
      <Container>
        <AdHocStatusBetaling setRedirect={this.setRedirect} nieuwepagina={this.nieuwepagina} />
      </Container>
    );
    paginas.set(scherm.REDIRECT, <RedirectPage redirect={this.state.redirect} />);

    return (
      <Container>
        <Menu
          token={this.state.token}
          pagina={this.state.pagina}
          shoppingbasket={this.state.shoppingbasket}
          nieuwepagina={this.nieuwepagina}
          download={this.state.download}
        />
        <Lader handleDB={this.handleDB} dbInit={this.state.dbInit} />
        {paginas.get(this.state.pagina)}
        <Footer />
      </Container>
    );
  }
}

/*
 * Redirect page
 */
class RedirectPage extends React.Component {
  constructor(props) {
    super(props);
    this.redirect = props.redirect;
  }

  componentDidMount() {
    window.location.replace(this.redirect);
  }

  render() {
    return null;
  }
}

/*
 * Render
 */

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <CssBaseline />
    <ApolloProvider client={apolloClient}>
      <GoogleOAuthProvider clientId="203544661987-k2khbtamirbd66dla0gsehnpprgrm9j5.apps.googleusercontent.com">
        <App />
      </GoogleOAuthProvider>
    </ApolloProvider>
  </ThemeProvider>,
  document.querySelector("#root")
);
