import React, { useEffect } from "react";

import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";

import { useMutation } from "@apollo/client";

import conf from "./conf.json";

import { GoogleLogin } from "@react-oauth/google";
import jwt_decode from "jwt-decode";

import { scherm, CREATE_ADHOC_BETALING } from "./common.js";

export { AdHocBetaling };

class Payment extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      bedrag: "",
      mededeling: "Eetfestijn " + new Date().getFullYear(),
      referentie: "",
      startbetaling: false,
    };

    this.setRedirect = props.setRedirect;
    this.nieuwepagina = props.nieuwepagina;

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleFocusChange = this.handleFocusChange.bind(this);
    this.setStartBetaling = this.setStartBetaling.bind(this);
  }
  // Knop aan wanneer alle verplichte velden ingevuld zijn
  isEnabled() {
    const minIngevuld = this.state.bedrag !== "" && this.state.mededeling !== "" ? true : false;

    return minIngevuld;
  }

  handleInputChange(event) {
    const target = event.target;
    this.setState({
      [target.name]: target.value,
    });
  }

  handleFocusChange(event) {
    const target = event.target;

    if (target.name === "bedrag" && target.value) {
      const value = parseFloat(target.value.replace(",", ".")).toFixed(2).replace(".", ",");
      this.setState({
        [target.name]: value,
      });
    }
  }

  setStartBetaling(startbetaling, url = null) {
    this.setState({ startbetaling: startbetaling });

    if (url) {
      this.setRedirect(url);
      this.nieuwepagina(scherm.REDIRECT);
    }
  }

  render() {
    return (
      <Box>
        <Box mt={10} >
          <Typography align="center" variant="h4">{`Aanmaken betaling`}</Typography>
        </Box>
        <Box pt={10} m={10} sx={{ display: "flex" }} justifyContent="center" alignItems="center">
          <Box width={"85%"}>
            <Grid container spacing={15}>
              <Grid item xs={12} align="left">
                <TextField
                  fullWidth
                  required
                  name="bedrag"
                  label="Bedrag te ontvangen (€)"
                  value={this.state.bedrag}
                  onChange={this.handleInputChange}
                  onBlur={this.handleFocusChange}
                />
              </Grid>

              <Grid item xs={12} align="left">
                <TextField
                  fullWidth
                  required
                  name="mededeling"
                  label="Omschrijving betaling"
                  value={this.state.mededeling}
                  onChange={this.handleInputChange}
                />
              </Grid>

              <Grid item xs={12} align="left">
                <TextField
                  fullWidth
                  name="referentie"
                  label="Interne referentie (optioneel)"
                  value={this.state.referentie}
                  onChange={this.handleInputChange}
                />
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Box mt={20} sx={{ display: "flex" }} justifyContent="center" alignItems="center">
          <Button
            disabled={!this.isEnabled()}
            style={{ backgroundColor: "#C1D82F", color: "#FFFFFF" }}
            variant="contained"
            onClick={() => {
              this.setState({ startbetaling: true });
            }}
          >
            <Typography variant="h6">QR code</Typography>
          </Button>
        </Box>

        <Box m={10} sx={{ display: "flex" }} justifyContent="center" alignItems="center">
          {this.state.startbetaling ? (
            <StuurBetaalgegevens
              startbetaling={this.state.startbetaling}
              bedrag={this.state.bedrag}
              mededeling={this.state.mededeling}
              referentie={this.state.referentie}
              medewerker={this.props.medewerker}
              setStartBetaling={this.setStartBetaling}
              setRedirect={this.setRedirect}
            />
          ) : null}
        </Box>
      </Box>
    );
  }
}

/*
 * Betaal pagina (adhoc betaal verzoek via QR code)
 */
class AdHocBetaling extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      medewerker: null,
    };
    this.token = props.token;
    this.setToken = props.setToken;
  }

  render() {
    return (
      <Box>
        {this.token == null ? (
          <Box>
            <Box m={20}>
              <Typography align="center" variant="h6">
                Log in met uw Harmonie Bornem account.
              </Typography>
            </Box>
            <Box m={20} display="flex" justifyContent="center" alignItems="center">
              <GoogleLogin
                onSuccess={(response) => {
                  const decoded = jwt_decode(response.credential);
                  const name = decoded.name;
                  console.log("Login OK: " + name);
                  this.setToken(response.credential);
                  this.token = response.credential;
                  this.setState({
                    medewerker: name,
                  });
                }}
                useOneTap
                auto_select
                onFailure={(response) => {
                  console.log("Fout bij inloggen: " + JSON.stringify(response));
                  this.setToken(null);
                }}
                hosted_domain="harmoniebornem.be"
              />
            </Box>
          </Box>
        ) : (
          <Box>
            <Payment
              medewerker={this.state.medewerker}
              nieuwepagina={this.props.nieuwepagina}
              setRedirect={this.props.setRedirect}
              token={this.token}
            />
          </Box>
        )}
      </Box>
    );
  }
}

/*
 * Stuur betaalgegevens naar server
 */
function StuurBetaalgegevens(props) {
  const [createAdHocBetaling, { data, loading, error }] = useMutation(CREATE_ADHOC_BETALING);

  useEffect(() => {
    createAdHocBetaling({
      variables: {
        bedrag: parseFloat(props.bedrag.replace(",", ".")), // is string, vermijd probleem komma / punt
        key: conf.key,
        mededeling: props.mededeling,
        referentie: props.referentie,
        medewerker: props.medewerker,
      },
    });
  }, [props.startbetaling]);

  if (loading) return <CircularProgress />;
  if (error) return `Fout bij betaling! ${error.message}`;

  if (data && data.createAdHocBetaling && data.createAdHocBetaling.betalingNr) {
    const { protocol, host, /* pathname */ } = window.location;
    const url = `${protocol}//${host}?betaling=${data.createAdHocBetaling.betalingNr}`;
    props.setStartBetaling(false, url);
  }

  return "Doorverwijzing naar betaalpagina ...";
}
