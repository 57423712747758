import React from "react";

import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

import CircularProgress from "@mui/material/CircularProgress";

import { useQuery } from "@apollo/client";

import { GET_ADHOC_BETALING_STATUS, scherm } from "./common.js";

export { AdHocStatusBetaling };

/*
 * Wacht scherm tot betaling van Mollie is ontvangen (pollen van status in database)
 */
function AdHocStatusBetaling(props) {
  const nieuwepagina = props.nieuwepagina;
  const setRedirect = props.setRedirect;

  const params = new URLSearchParams(document.location.search.substring(1));
  const nr = parseInt(params.get("betaling"), 10);
  const { loading, error, data } = useQuery(GET_ADHOC_BETALING_STATUS, {
    variables: { betalingNr: nr },
    pollInterval: 250,
  });

  if (loading) return <p>Loading...</p>;
  if (error) {
    console.error(error);
    return <p>Error :(</p>;
  }

  const status = data.adHocBetalingStatus;
  const qrCode = status && status.status === "open" && status.qrCode && status.qrCode !== "";
  const waiting = status == null || (status.status === "open" && !qrCode);
  const ok = !waiting && !qrCode && status.status === "paid" ? true : false;
  const nok = !waiting && !ok && !qrCode;

  let background;
  if (qrCode) background = "white";
  if (waiting) background = "yellow";
  if (ok) background = "green";
  if (nok) background = "red";

  return (
    <Box>
      <Box sx={{ display: "flex" }} justifyContent="center" alignItems="center">
        <Box m={20} p={15} sx={{ width: "100%", bgcolor: { background } }}>
          {waiting ? (
            <Box p={15} sx={{ display: "flex" }} justifyContent="center" alignItems="center">
              <CircularProgress />
            </Box>
          ) : null}

          <Box>
            <Typography variant="h4" align="center">
              {status.bedrag.toLocaleString()}&euro;
            </Typography>
          </Box>

          {qrCode ? (
            <Box pt={15} sx={{ display: "flex" }} justifyContent="center" alignItems="center">
              <img src={status.qrCode}  />
            </Box>
          ) : null}

          {ok ? (
            <Box pb={8} pt={15}>
              <Typography variant="h5" align="center">
                Betaling ontvangen.
              </Typography>
              <Typography variant="h5" align="center">
                Hartelijk dank!
              </Typography>
            </Box>
          ) : null}

          {nok ? (
            <Box pb={8} pt={15}>
              <Typography variant="h5" align="center">
                Betaling niet gelukt.
              </Typography>
              <Typography mt={10} variant="h6" align="center">
                Status: {status.status}
              </Typography>
              <Typography variant="h6" align="center">
                Nr: {status.nr}
              </Typography>
              {status.fout && status.fout !== "" ? (
                <Typography variant="h6" align="center">
                  Fout: {status.fout}
                </Typography>
              ) : null}
            </Box>
          ) : null}
        </Box>
      </Box>

      <Box mb={20} sx={{ display: "flex" }} justifyContent="center" alignItems="center">
        <Button
          style={{ backgroundColor: "#C1D82F", color: "#FFFFFF" }}
          variant="contained"
          onClick={() => {
            const { protocol, host, pathname } = window.location;
            const fullUrlWithoutParams = `${protocol}//${host}${pathname}pay`;
            setRedirect(fullUrlWithoutParams);
            nieuwepagina(scherm.REDIRECT);
          }}
        >
          <Typography variant="h5">
            {waiting || qrCode || nok ? "STOP" : null}
            {ok ? "OK" : null}
          </Typography>
        </Button>
      </Box>
    </Box>
  );
}
