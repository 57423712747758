import { createTheme } from "@mui/material/styles";
import { red } from "@mui/material/colors";

import { gql, ApolloClient, InMemoryCache } from "@apollo/client";

import conf from "./conf.json";

export {
  theme,
  GET_MENU,
  GET_BETALING_STATUS,
  GET_OVERZICHT_BESTELLINGEN,
  GET_OVERZICHT_BESTELLING,
  CREATE_BESTELLING,
  UPDATE_BETALING_STATUS,
  CREATE_ADHOC_BETALING,
  GET_ADHOC_BETALING_STATUS,
  GET_OVERZICHT_ADHOC_BETALINGEN,
  scherm,
  apolloClient,
};

/*
 * Material UI theme
 */
const theme = createTheme({
  spacing: 2,
  palette: {
    primary: {
      main: "#556cd6",
    },
    secondary: {
      main: "#19857b",
    },
    error: {
      main: red.A400,
    },
    habor: {
      groen: "#00A887",
      wit: "#FFFFFF",
    },
  },
});

/*
 * GraphQL
 */
const GET_MENU = gql`
  query GetMenu($key: String!) {
    menu(key: $key) {
      titel
      begroeting
      dagen
      gerechtgroepen {
        gerechten {
          naam
          beschrijving
          lange_beschrijving
          foto
          prijs
        }
        naam
      }
    }
  }
`;

const GET_BETALING_STATUS = gql`
  query GetBetalingStatus($bestellingNr: Int!) {
    betalingStatus(bestellingNr: $bestellingNr) {
      bestellingNr
      betalingNr
      klantNr
      voornaam
      naam
      email
      referentie
      bedrag
      bestellingStatus
      betalingStatus
      email_ok
      levering
      straat
      huis
      postcode
      plaats
      opmerkingen
      dag
      method
    }
  }
`;

const GET_OVERZICHT_BESTELLINGEN = gql`
  query GetOverzichtAlleBestellingen($token: String!) {
    overzichtAlleBestellingenJSON(token: $token)
  }
`;

const GET_OVERZICHT_BESTELLING = gql`
  query GetOverzichtBestelling($token: String!, $bestellingNr: Int!) {
    overzichtBestellingJSON(token: $token, bestellingNr: $bestellingNr)
  }
`;

const CREATE_BESTELLING = gql`
  mutation CreateBestelling(
    $voornaam: String
    $naam: String
    $email: String
    $gerechten: [String]
    $totaal: Float
    $key: String
    $levering: String
    $straat: String
    $huis: String
    $postcode: String
    $plaats: String
    $opmerkingen: String
    $dag: String
  ) {
    createBestelling(
      verzoek: {
        voornaam: $voornaam
        naam: $naam
        email: $email
        gerechten: $gerechten
        totaal: $totaal
        key: $key
        levering: $levering
        straat: $straat
        huis: $huis
        postcode: $postcode
        plaats: $plaats
        opmerkingen: $opmerkingen
        dag: $dag
      }
    ) {
      betaalUrl
      bestellingNr
    }
  }
`;

const UPDATE_BETALING_STATUS = gql`
  mutation UpdateBetalingStatus($bestellingNr: Int!) {
    updateBetalingStatus(bestellingNr: $bestellingNr)
  }
`;

const CREATE_ADHOC_BETALING = gql`
  mutation CreateAdHocBetaling(
    $bedrag: Float
    $key: String
    $mededeling: String
    $referentie: String
    $medewerker: String
  ) {
    createAdHocBetaling(
      verzoek: {
        bedrag: $bedrag
        key: $key
        mededeling: $mededeling
        referentie: $referentie
        medewerker: $medewerker
      }
    ) {
      betalingNr
    }
  }
`;

const GET_ADHOC_BETALING_STATUS = gql`
  query GetAdHocBetalingStatus($betalingNr: Int!) {
    adHocBetalingStatus(betalingNr: $betalingNr) {
      status
      nr
      bedrag
      aangemaakt
      mededeling
      medewerker
      referentie
      naam
      rekening
      fout
      qrCode
    }
  }
`;

const GET_OVERZICHT_ADHOC_BETALINGEN = gql`
  query GetOverzichtAdHocBetalingen($token: String!) {
    overzichtAdHocBetalingenJSON(token: $token)
  }
`;

/*
 * Schermen
 */
const scherm = {
  HOMEPAGINA: "homepagina",
  WINKELKAR: "winkelkar",
  BETALING: "betaling",
  WACHTEN: "wachten",
  BEDANKT: "bedankt",
  REDIRECT: "redirect",
  ADMIN: "admin",
  ADHOC_BETALING: "adhoc_betaling",
  ADHOC_WACHTEN: "adhoc_wachten",
};

/*
 * GraphQL client
 */
const apolloClient = new ApolloClient({
  uri: conf.uri_api,
  cache: new InMemoryCache(),
});
